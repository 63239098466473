<template>
  <a-layout-content style="overflow-x: auto">
    <a-button
      :style="{margin: '0 0 0 16px'}"
      :disabled="isBackDisabled"
      @click="goBack"
    >
      <template #icon>
        <RollbackOutlined />
      </template>
      {{ $t('components.googleDriveView.backButtonText') }}
    </a-button>
    <a-table
      class="storage-table table-padded"
      size="small"
      :scroll="{ x: 600, y: 500 }"
      :loading="loading"
      :data-source="currentPageFiles"
      :columns="columns"
      position="topCenter"
      :pagination="false"
      :row-selection="rowSelection"
      :custom-row="customRow"
      :row-class-name="rowClassName"
      @resizeColumn="handleResizeColumn"
    >
      <template #headerCell="{ column }">
        <template v-if="column.key">
          {{ $t(`components.googleDriveView.${column.key}`) }}
        </template>
      </template>
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.dataIndex === 'mimeType'">
          <a-tooltip v-if="text === 'application/vnd.google-apps.folder'">
            <template #title>
              {{ $t('components.googleDriveView.goToFolderTooltipTitle') }}
            </template>
            <FolderFilled
              style="font-size: 18px; cursor: pointer"
              @click.prevent.stop="openFolder(record.id)"
            />
          </a-tooltip>
          <span
            v-else
            style="display: inline-block"
          >
            <img
              :src="record.iconLink"
            >
          </span>
        </template>
        <template v-else-if="column.dataIndex === 'name'">
          <template v-if="record.mimeType === 'application/vnd.google-apps.folder'">
            <a
              class="table-link"
              @click.prevent.stop="openFolder(record.id)"
            >
              {{ text }}
            </a>
          </template>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <template v-else-if="column.dataIndex === 'thumbnailLink'">
          <a-image
            v-if="text"
            :height="40"
            :src="text"
            :preview="false"
            :fallback="fallback"
            @click.stop.prevent="openWebViewLink(record.webViewLink)"
          />
          <div
            v-else
            class="img-spacer"
          />
        </template>
        <template v-else-if="column.dataIndex === 'createdTime'">
          {{ formatDate(text) }}
        </template>
        <template v-else-if="column.dataIndex === 'size'">
          {{ formatFileSize(text) }}
        </template>
        <template v-else>
          {{ text }}
        </template>
      </template>
    </a-table>
    <div class="pagination">
      <a-space>
        <a-button
          size="small"
          :disabled="isPagePrevDisabled"
          @click="prevPage"
        >
          <template #icon>
            <LeftOutlined />
          </template>
        </a-button>
        <a-button
          size="small"
          :disabled="isPageNextDisabled"
          @click="nextPage"
        >
          <template #icon>
            <RightOutlined />
          </template>
        </a-button>
      </a-space>
    </div>
  </a-layout-content>
  <StickyFooter v-if="selectedMediaIds?.length">
    <FixedFooter>
      <template #left>
        {{ selectedMediaIds.length }} {{ selectedMediaIds.length === 1 ? 'File' : 'Files' }} Selected
      </template>
      <a-button
        size="small"
        @click="deselectAll"
      >
        {{ $t('components.googleDriveView.deselectAllButtonText') }}
      </a-button>
    </FixedFooter>
  </StickyFooter>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { FolderFilled, LeftOutlined, RightOutlined, RollbackOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash'
import { error, formatDate, formatFileSize } from '@/utils'
import { ACCEPTED_MIME_TYPES, FALLBACK_IMAGE, MEDIA_TYPES } from '@/constants'
import FixedFooter from '@/components/FixedFooter'
import { SlideAsset } from '@/helpers/Slides'
import StickyFooter from '@/components/StickyFooter.vue'

const SOURCE_TYPE = 'GOOGLE_DRIVE_ACCOUNT'

const COLUMNS = [
  {
    title: '',
    dataIndex: 'mimeType',
    align: 'center',
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    fixed: 'left',
    defaultSortOrder: 'descend'
  }, {
    title: 'fileName',
    dataIndex: 'name',
    key: 'fileName',
    resizable: true,
    ellipsis: true,
    width: 240,
    maxWidth: 800,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.name.localeCompare(b.name),
      multiple: 2
    }
  }, {
    title: 'createdDate',
    dataIndex: 'createdTime',
    key: 'createdDate',
    width: 200,
    minWidth: 100,
    maxWidth: 400,
    sorter: {
      compare: (a, b) => a.createdAtSeconds - b.createdAtSeconds
    }
  }, {
    title: 'preview',
    dataIndex: 'thumbnailLink',
    key: 'preview',
    width: 100,
    minWidth: 100,
    maxWidth: 110
  }, {
    title: 'size',
    dataIndex: 'size',
    key: 'size',
    width: 100,
    minWidth: 100,
    maxWidth: 300,
    sorter: {
      compare: (a, b) => a.size - b.size
    }
  }]

export default defineComponent({
  name: 'GoogleDriveView',
  components: {
    StickyFooter,
    FixedFooter,
    FolderFilled,
    RollbackOutlined,
    LeftOutlined,
    RightOutlined
  },
  props: {
    googleAccountId: String,
    mediaTypes: {
      type: String,
      validator (value) {
        return MEDIA_TYPES.indexOf(value) !== -1
      }
    },
    selection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      }
    },
    excludeHeadings: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const store = useStore()
    const currentPath = ref([])
    const currentPagePath = ref([])
    const nextPageToken = ref(null)
    const selectedKeys = ref([])
    const parentId = computed(() => currentPath.value?.at(-1) || 'root')
    const pageToken = computed(() => currentPagePath.value?.at(-1) || null)
    const currentPageFiles = ref([])
    const loading = ref(true)
    const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])
    const isBackDisabled = computed(() => !currentPath.value?.length || loading.value)
    const isPagePrevDisabled = computed(() => !currentPagePath.value?.length || loading.value)
    const isPageNextDisabled = computed(() => !nextPageToken.value || loading.value)
    const acceptedMimeTypes = props.mediaTypes ? ACCEPTED_MIME_TYPES[props.mediaTypes] : null

    onMounted(() => {
      fetchFiles()
    })

    const fetchFiles = async () => {
      loading.value = true
      const { files, nextPageToken: nextToken } = await store.dispatch('media/fetchGoogleDriveFiles', {
        googleSocialAccountId: props.googleAccountId,
        params: {
          parentId: parentId.value,
          ...(pageToken.value ? { pageToken: pageToken.value } : null),
          pageSize: 10
        }
      }).catch(e => {
        error(e.message)
      })
      nextPageToken.value = nextToken || null
      currentPageFiles.value = files.map(f => ({ ...f, key: f.id, disabled: acceptedMimeTypes && !acceptedMimeTypes.includes(f.mimeType) }))
      loading.value = false
    }

    const openWebViewLink = (url) => {
      window.open(url, '_blank').focus()
    }

    const openFolder = (id) => {
      currentPagePath.value = []
      currentPath.value.push(id)
      fetchFiles()
    }

    const goBack = () => {
      currentPagePath.value = []
      currentPath.value.pop()
      fetchFiles()
    }
    const nextPage = () => {
      currentPagePath.value.push(nextPageToken.value)
      fetchFiles()
    }

    const prevPage = () => {
      currentPagePath.value.pop()
      fetchFiles()
    }

    const onSelectChange = (selection) => {
      selectedKeys.value = cloneDeep(selection)
      selectMedia(selection)
    }

    const selectMedia = (selection = []) => {
      const selected = selection?.map(id => {
        const { name, thumbnailLink: thumbnail, webContentLink: source } = currentPageFiles.value.find(f => f.id === id) || {}
        return new SlideAsset({
          id,
          name,
          thumbnail,
          source,
          sourceType: SOURCE_TYPE,
          mediaReference: {
            socialGoogleAccountId: props.googleAccountId,
            googleDriveMediaId: id,
            sourceType: SOURCE_TYPE
          }
        })
      })
      store.commit('media/SET_SELECTED_MEDIA', selected)
    }

    const rowSelection = computed(() => {
      if (props.selection === 'multiple') {
        return {
          onChange: onSelectChange,
          selectedRowKeys: selectedKeys.value,
          getCheckboxProps: record => {
            return {
              disabled: record.disabled
            }
          }
        }
      }
      return null
    })

    const customRow = (record) => {
      if (props.selection === 'single') {
        return {
          onClick: () => {
            if (record.disabled) return
            selectedKeys.value = [record.id]
            selectMedia([record.id])
          }
        }
      } else if (props.selection === 'multiple') {
        return {
          onClick: () => {
            if (record.disabled) return
            if (!selectedKeys.value?.includes(record.id)) {
              selectedKeys.value.push(record.id)
            } else {
              selectedKeys.value = selectedKeys.value.filter(r => r !== record.id)
            }
            selectMedia(cloneDeep(selectedKeys.value))
          }
        }
      }
      return null
    }

    const rowClassName = (record) => {
      const className = props.selection === 'single' && !record.disabled ? 'selectable ' : ''
      return selectedKeys.value?.includes(record.id) ? className + 'ant-table-row-selected selected' : className
    }

    const deselectAll = () => {
      store.commit('media/CLEAR_SELECTED_MEDIA')
    }

    watch(selectedMediaIds, (mediaIds) => {
      selectedKeys.value = mediaIds
    })

    return {
      currentPageFiles,
      loading,
      isPagePrevDisabled,
      isPageNextDisabled,
      fallback: FALLBACK_IMAGE,
      columns: COLUMNS,
      isBackDisabled,
      rowSelection,
      selectedMediaIds,
      rowClassName,
      customRow,
      goBack,
      openFolder,
      nextPage,
      prevPage,
      formatDate,
      deselectAll,
      openWebViewLink,
      formatFileSize,
      handleResizeColumn: (w, col) => {
        col.width = w
      }
    }
  }
})
</script>

<style lang="less" scoped>
  .img-spacer {
    height: 40px;
  }
  .pagination {
    margin: 0 16px 16px;
    display: flex;
    justify-content: flex-end;
  }
  .ant-table-row  {
    &.selectable {
      cursor: pointer;
    }
    &.selected {
      .ant-table-cell {
        background-color: var(--ant-primary-1);
      }
    }
  }
</style>
